// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
; (function ($, window, document, undefined) {

	"use strict";

	// undefined is used here as the undefined global variable in ECMAScript 3 is
	// mutable (ie. it can be changed by someone else). undefined isn't really being
	// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
	// can no longer be modified.

	// window and document are passed through as local variable rather than global
	// as this (slightly) quickens the resolution process and can be more efficiently
	// minified (especially when both are regularly referenced in your plugin).

	// Create the defaults once
	var pluginName = "carousel";
	var defaults = {
		carousel: '.js-carousel',
		viewport: '.js-carousel__viewport',
		content: '.js-carousel__content',
		item: '.js-carousel__item',
		control: '.js-carousel__control',
		prev: '.js-carousel__prev',
		next: '.js-carousel__next',
		itemsVisible: 3,
		itemsToScroll: 3,
		itemMargin: 10,
		hasBreakpoint: false,
		breakpointValue: 800,
		itemsAboveBreakpoint: 3,
		itemsBelowBreakpoint: 2
	};

	// The actual plugin constructor
	function Plugin(element, options) {
		this.element = element;
		this.$element = $(this.element);
		// jQuery has an extend method which merges the contents of two or
		// more objects, storing the result in the first object. The first object
		// is generally empty as we don't want to alter the default options for
		// future instances of the plugin
		this.settings = $.extend({}, defaults, options);
		//this.settings.resizeDebounce = undefined;

		this.$viewport = this.$element.find(this.settings.viewport);
		this.$content = this.$element.find(this.settings.content);
		this.$controls = this.$element.find(this.settings.control);
		this.$prevControl = this.$element.find(this.settings.prev);
		this.$nextControl = this.$element.find(this.settings.next);
		this.$items = this.$content.find(this.settings.item);
		this.numberOfItems = this.$items.length;
		this.currentItem = 0;
		this.itemsToScroll = this.settings.itemsToScroll;
		this.lastVisibleItem = this.settings.itemsVisible - 1;
		this.lazyloadTimer = null;

		this._defaults = defaults;
		this._name = pluginName;
		this.init();
	}

	// Avoid Plugin.prototype conflicts
	$.extend(Plugin.prototype, {
		init: function () {
			// Place initialization logic here
			// You already have access to the DOM element and
			// the options via the instance, e.g. this.element
			// and this.settings
			// you can add more functions like the one below and
			// call them like so: this.yourOtherFunction(this.element, this.settings).
			this.setCarouselSize();
			this.initSubscriptions();
			this.initEvents();
		},

		setCarouselSize: function () {
			// get available width
			var carouselWidth = this.$viewport.width();
			var itemsVisible = this.settings.itemsVisible;

			// determine whether the number of thumbnails needs to change
			if (this.settings.hasBreakpoint) {
				itemsVisible = carouselWidth > this.settings.breakpointValue ? this.settings.itemsAboveBreakpoint : this.settings.itemsBelowBreakpoint;
			}
			this.itemsVisible = itemsVisible;

			// calculate thumbnail width
			var itemWidth = (carouselWidth / itemsVisible);

			if (this.settings.itemMargin > 0) {
				itemWidth = (itemWidth + (this.settings.itemMargin / 2));
			}

			// resize thumbnail carousel + items
			this.$items.css('width', itemWidth + 'px');
			this.$content.width((itemWidth * this.numberOfItems) + 10);
			this.itemWidth = itemWidth;
			//this.$thumbsCarousel.addClass('is-visible');

			if (this.$items.length > this.itemsVisible) {
				this.$controls.removeClass('hidden');
			}
		},

		initSubscriptions: function() {
			$.subscribe('/rotator/menuChange', $.proxy(this.autoRotateCarousel, this));
		},

		initEvents: function () {
			$(window).on('resize', { plugin: this }, this.onResize);
			this.$element.on('click', this.settings.control, { plugin: this }, this.onControlClick);
		},

		onControlClick: function (e) {
			e.preventDefault();
			var plugin = e.data.plugin;
			var $button = $(this);
			var direction = $button.attr('data-direction');

			plugin.updateCarouselPosition(direction);
		},

		autoRotateCarousel: function (data) {
			if (data.itemIndex === 0) {
				this.currentItem = 0;
				this.lastVisibleItem = this.itemsVisible - 1;
				this.transitionCarousel(0);
				this.updateControls(0);
			} else if (data.itemIndex > this.lastVisibleItem) {
				this.updateCarouselPosition('next');
			}
		},

		updateCarouselPosition: function (direction) {
			var currentPosition = this.currentItem * this.itemWidth;
			var maxOffset = (this.itemWidth * (this.numberOfItems - this.itemsVisible));
			var itemsToScroll = this.itemsToScroll;

			if (direction === 'next' && currentPosition < maxOffset) {
				var itemsAhead = this.numberOfItems - (this.currentItem + this.itemsVisible);
				if (itemsAhead < itemsToScroll) {
					itemsToScroll = itemsAhead;
				}
				this.currentItem = this.currentItem + itemsToScroll;
			} else if (direction === 'prev' && currentPosition > 0) {
				var itemsBehind = this.currentItem;
				if (itemsBehind < itemsToScroll) {
					itemsToScroll = itemsBehind;
				}
				this.currentItem = this.currentItem - itemsToScroll;
			}

			this.lastVisibleItem = this.currentItem + (this.itemsVisible - 1);
			var newPosition = this.currentItem * this.itemWidth;

			if (newPosition !== currentPosition) {
				this.transitionCarousel(newPosition);
				this.updateControls(newPosition);
			}
		},

		// shift the positioning of the carousel
		transitionCarousel: function (pos) {
			this.$content.css('left', '-' + pos + 'px');
			this.lazyloadTimer = window.setTimeout(this.triggerLazyload, 200);
		},

		triggerLazyload: function () {
			$(window).trigger('scroll');
		},

		onResize: function (e) {
			if (!e || !e.data || !e.data.plugin || e.data.plugin._name !== 'carousel') {
				return;
			}
			e.data.plugin.setCarouselSize();
		},

		updateControls: function (pos) {
			var maxOffset = (this.itemWidth * (this.numberOfItems - this.itemsVisible));
			var disabledClass = 'is-disabled';

			if (pos > 0) {
				this.$prevControl.removeClass(disabledClass);
			}

			if (pos === 0) {
				this.$prevControl.addClass(disabledClass);
			}

			if (pos === maxOffset) {
				this.$nextControl.addClass(disabledClass);
			}

			if (pos < maxOffset) {
				this.$nextControl.removeClass(disabledClass);
			}
		}

	});

	// A really lightweight plugin wrapper around the constructor,
	// preventing against multiple instantiations
	$.fn[pluginName] = function (options) {
		return this.each(function () {
			if (!$.data(this, "plugin_" + pluginName)) {
				$.data(this, "plugin_" + pluginName, new Plugin(this, options));
			}
		});
	};

})(jQuery, window, document);