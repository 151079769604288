// this imports the same libraries that used to be loaded by requireJS
// next step is to conditionally import modules actually used on a page
//
import 'jquery';
import 'jquery-ui';
import 'jquery.easing';
import 'magnific-popup';
import 'fg-ajax-include';
import 'jquery-ajax-unobtrusive';
import 'jquery-lazyload';
import 'jquery-throttle-debounce';
import 'pubsub';
import 'lib/terabyte/jquery.lazyscroll';
import 'lib/terabyte/jquery.expandcollapse';
import 'lib/terabyte/jquery.evensteven';
import 'lib/terabyte/jquery.tablescroll';
import 'lib/terabyte/jquery.phatfingaz';
import 'enquire.js';
import 'jquery-hoverintent';
import 'lib/terabyte/jquery.tabbery';
import 'lib/terabyte/jquery.brotator';
import 'jquery.actual';
import 'lib/terabyte/jquery.carousel';
import 'lib/terabyte/jquery.placeholdit';
import 'lib/terabyte/jquery.printernator'; // possibly unreferenced
import 'lib/Twitter';
import 'templayed';
import 'lib/plupload.full'; //npm plupload@2.2 -- need to review manual fix
import 'lib/jquery.progressbar.min'; // impossible to determine upstream package
import 'scrollspy';
import 'duet';
import 'pikaday';
import 'jquery-match-height';

// this makes it globally available
// next step: only import this where it is actually used
import { io } from "socket.io-client";
window.io = io;